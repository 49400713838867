import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import Layout from "components/Layout";
import Partenaires from "components/Partenaires";

const Aide = ({ pageContext: content, location }) => {
  const {
    _companies,
    _countries,
    _others,
    _otherCountries,
    _partners,
    _staticTexts,
    _richTextBlock,
    _topMenus,
    _companyMenus,
    _lang,
 _blogs,
  } = content;

  const sidebarContent = useMemo(() => {
    let content = "";

    let _sidebarContent = _richTextBlock.find(
      (tb) => tb.title === "sidebar_other_countries"
    );

    if (_sidebarContent) {
      try {
        _sidebarContent = _sidebarContent.richText.content;

        content = _sidebarContent[0].content[0].value;
      } catch (error) {
        console.log(error);
      }
    }

    return content;
  }, [_richTextBlock]);

  return (
    <Layout
      menuContent={{
        companies: _companies,
        countries: _countries,
        others: _others,
        otherCountries: _otherCountries,
        topMenus: _topMenus,
        companyMenus: _companyMenus,
        blogs: _blogs,
      }}
      ratingValue={content.ratingValue || 4.9}
      ratingCount={content.ratingCount || 121}
      staticTexts={_staticTexts}
      lang={_lang}
    >
      <Helmet>
        <title>{_staticTexts.templateNousContacterH1} | Losali Direct</title>
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content={`${_staticTexts.templateNousContacterH1} | Losali Direct`}
        />
        <meta
          name="description"
          content={`${_staticTexts.templateNousContacterH1} | Losali Direct`}
        />
      </Helmet>
      <main className="container page-width top-margin">
        <div className="page2-box1 card-primary">
          <div className="aide other-card page2-card-left">
            <form
              name="nous-contracter"
              method="POST"
              action="/merci"
              data-netlify="true"
            >
              <h1 className="main-card-h1">
                {_staticTexts.templateNousContacterH1}
              </h1>

              <label htmlFor="name">
                <h4>
                  {_staticTexts.templateNousContacterName}{" "}
                  <span className="required-star">*</span>
                </h4>
              </label>

              <input type="text" id="name" name="name" required />

              <label htmlFor="email">
                <h4>
                  {_staticTexts.templateNousContacterEmail}{" "}
                  <span className="required-star">*</span>
                </h4>
              </label>
              <input type="email" id="email" name="email" required />

              <label htmlFor="subject">
                <h4>
                  {_staticTexts.templateNousContacterSubject}{" "}
                  <span className="required-star">*</span>
                </h4>
              </label>
              <input type="text" id="subject" name="subject" required />

              <label htmlFor="message">
                <h4>
                  {_staticTexts.templateNousContacterMessage}{" "}
                  <span className="required-star">*</span>
                </h4>
              </label>
              <textarea
                id="message"
                name="message"
                rows="3"
                cols="50"
                required
              ></textarea>

              <input
                type="hidden"
                name="form-name"
                value="nous-contracter"
                required
              />

              <button
                type="submit"
                className="losali-btn"
                style={{ float: "right" }}
              >
                {_staticTexts.templateNousContacterSend}
              </button>
            </form>
          </div>
        </div>

        <div className="page2-box2 hide-mobile">
          <div className="other-card-right page2-card-right card-primary">
            <div className="page2-right-text">
              <h3>{sidebarContent}</h3>

              <div className="social-media">
                <span className="social-media-like float-left"></span>
                <a
                  href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.losalidirect.com%2F&display=popup&ref=plugin&src=like&kid_directed_site=0"
                  target="_blank"
                  rel="noreferrer"
                  className="social-media-text"
                >
                  {_staticTexts.like}
                </a>
              </div>

              <div className="social-media">
                <span className="social-media-share float-left"></span>
                <span
                  onClick={() => {
                    navigator.clipboard.writeText(`https://losalidirect.com`);
                  }}
                  aria-hidden="true"
                  className="social-media-text"
                >
                  {_staticTexts.share}
                </span>
              </div>

              <div className="social-media">
                <span className="social-media-tweet float-left"></span>
                <a
                  href="https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Flosalidirect.com%2F&ref_src=twsrc%5Etfw&text=Avec%20Losali%20Direct%20appelez%20les%20num%C3%A9ros%20courts%20et%20surtax%C3%A9s%20depuis%20l%27%C3%A9tranger&tw_p=tweetbutton&url=https%3A%2F%2Fwww.losalidirect.com%2F&via=Narga"
                  target="_blank"
                  rel="noreferrer"
                  className="social-media-text"
                >
                  {_staticTexts.tweet}
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Partenaires partners={_partners} />
    </Layout>
  );
};

export default Aide;
