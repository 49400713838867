import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Partenaires = ({ partners }) => {
  const getFields = () => {
    try {
      const leftLink = partners.leftLink.content[0].content.find(
        (c) => c.nodeType === "hyperlink"
      );
      const rightLink = partners.rightLink.content[0].content.find(
        (c) => c.nodeType === "hyperlink"
      );

      return {
        leftImg: partners.leftImg.map((img) => ({
          url: img.fields.file.url,
          description: img.fields.description,
        })),
        leftLink: leftLink ? leftLink.data.uri : undefined,
        leftLinkContent: leftLink
          ? leftLink.content[0].value
          : partners.leftLink.content[0].content[0].value,
        leftTitle: partners.leftTitle,
        rightImg: partners.rightImg.map((img) => ({
          url: img.fields.file.url,
          description: img.fields.description,
        })),
        rightLink: rightLink ? rightLink.data.uri : undefined,
        rightLinkContent: rightLink
          ? rightLink.content[0].value
          : partners.rightLink.content[0].content[0].value,
        rightTitle: partners.rightTitle,
      };
    } catch (error) {
      console.log(error);
    }

    return undefined;
  };

  const [fields] = useState(getFields());

  return fields ? (
    <section className="container page-width">
      <div className="partenaires-card card-primary">
        <div className="partenaires-card-left">
          <div className="h2-title">{fields.leftTitle}</div>
          <div className="partenaires-images">
            {fields.leftImg.map((img, index) => (
              <div key={index}>
                {img.url && (
                  <a
                    href={partners.leftLinks[index]}
                    className="partenaires-block"
                  >
                    <LazyLoadImage
                      src={img.url}
                      alt={img.description}
                      width="100px"
                      height="40px"
                    />
                  </a>
                )}
              </div>
            ))}
          </div>

          <div className="partenaires-footer">
            {fields.leftLink ? (
              <a href={fields.leftLink}>{fields.leftLinkContent}</a>
            ) : (
              <span>{fields.leftLinkContent}</span>
            )}
            <StaticImage
              src="../assets/icons/vector.svg"
              placeholder="blurred"
              layout="fixed"
              alt="Ils parlent de Losali"
            />
          </div>
        </div>
        <div className="partenaires-card-left">
          <div className="h2-title">{fields.rightTitle}</div>
          <div className="partenaires-images">
            {fields.rightImg.map((img, index) => (
              <div key={index}>
                {img.url && (
                  <a
                    href={partners.rightLinks[index]}
                    className="partenaires-block"
                    key={index}
                  >
                    <LazyLoadImage
                      src={img.url}
                      alt={img.description}
                      width="100px"
                      height="40px"
                    />
                  </a>
                )}
              </div>
            ))}
          </div>

          <div className="partenaires-footer">
            {fields.rightLink ? (
              <a href={fields.rightLink}>{fields.rightLinkContent}</a>
            ) : (
              <span>{fields.rightLinkContent}</span>
            )}
            <StaticImage
              src="../assets/icons/vector.svg"
              placeholder="blurred"
              layout="fixed"
              alt="Nos partenaires"
            />
          </div>
        </div>
      </div>
    </section>
  ) : null;
};

export default Partenaires;
